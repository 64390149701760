import clone from "../helpers/clone";
import update from "immutability-helper";
import { IVarsStore, VarsTypes } from "../types/stores/vars";

export const LOAD_SINGLE_VIEW_DESTINATIONS = "LOAD_SINGLE_VIEW_DESTINATIONS";
export const LOAD_FIELDS = "LOAD_FIELDS";
export const SET_FIELD_TAB_PERMISSIONS = "SET_FIELD_TAB_PERMISSIONS";
export const SET_LAST_TABLEKEY = "SET_LAST_TABLEKEY";
export const INC_AJAX_COUNT = "INC_AJAX_COUNT";
export const DEC_AJAX_COUNT = "DEC_AJAX_COUNT";
export const START_RENAMING_FOLDER = "START_RENAMING_FOLDER";
export const STOP_RENAMING_FOLDER = "STOP_RENAMING_FOLDER";
export const ASSIGN_NEW_FOLDER_ID = "ASSIGN_NEW_FOLDER_ID";
export const START_RENAMING_FIELD_FOLDER = "START_RENAMING_FIELD_FOLDER";
export const STOP_RENAMING_FIELD_FOLDER = "STOP_RENAMING_FIELD_FOLDER";
export const SET_PAGE = "SET_PAGE";
export const SET_TAPAD_DELIVERY_TYPES = "SET_TAPAD_DELIVERY_TYPES";
export const SET_ACTIVATION_DESTINATION_SCHEDULES = "SET_ACTIVATION_DESTINATION_SCHEDULES";
export const SET_DESTINATION_DEDUPE_SETTINGS = "SET_DESTINATION_DEDUPE_SETTINGS";
export const SET_LAYOUT_TYPES = "SET_LAYOUT_TYPES";
export const SET_CASS_REPORTS = "SET_CASS_REPORTS";
export const SET_LAYOUT_SVFIELDS = "SET_LAYOUT_SVFIELDS";
export const SET_COMPRESSION_TYPES = "SET_COMPRESSION_TYPES";
export const SET_CLIENT_FIELD = "SET_CLIENT_FIELD";
export const SET_VAR = "SET_VAR";
export const GENERIC_QB_ADD_FIELD = "GENERIC_QB_ADD_FIELD";
export const GENERIC_QB_ADD_FIELD_MULTI = "GENERIC_QB_ADD_FIELD_MULTI";
export const GENERIC_QB_CLEAR_FIELD = "GENERIC_QB_CLEAR_FIELD";
export const SET_MODEL_FILENAMES = "SET_MODEL_FILENAMES";
export const SET_MODEL_BUNDLES = "SET_MODEL_BUNDLES";
export const SET_MODEL_VARIABLES = "SET_MODEL_VARIABLES";
export const SET_MODEL_VARIABLES_EXCLUDED = "SET_MODEL_VARIABLES_EXCLUDED";
export const SET_UPDATING_MODEL_BUNDLES = "SET_UPDATING_MODEL_BUNDLES";
export const SET_LOADING_DESTINATION_ORDER = "SET_LOADING_DESTINATION_ORDER";
export const SET_LOADING_COMPANY = "SET_LOADING_COMPANY";
export const SET_MIGRATION_THINKING = "SET_MIGRATION_THINKING";
export const SET_CLIPBOARD_NODES = "SET_CLIPBOARD_NODES";
export const SET_SELECTION_MODE = "SET_SELECTION_MODE";
export const SET_CLIPBOARD_RELATIONS = "SET_CLIPBOARD_RELATIONS";
export const SET_MULTI_DESTINATION_TAB = "SET_MULTI_DESTINATION_TAB";
export const SET_NOT_FOUND = "SET_NOT_FOUND";
export const SET_VARIABLE_GROUPINGS = "SET_VARIABLE_GROUPINGS";
export const SET_VARIABLE_DATA_TYPES = "SET_VARIABLE_DATA_TYPES";
export const SET_VARIABLE_DATA_TYPES_FULL = "SET_VARIABLE_DATA_TYPES_FULL";
export const LOAD_DESTINATION_FIELD_RESTRICTIONS = "LOAD_DESTINATION_FIELD_RESTRICTIONS";
export const SET_PREVIOUS_URL_ID_SIMPLE_FLOW = "SET_PREVIOUS_URL_ID_SIMPLE_FLOW";
export const SET_DASHBOARD_STATUS = "SET_DASHBOARD_STATUS";
export const SET_REDIRECT_OVERRIDE_SIMPLE_FLOW = "SET_REDIRECT_OVERRIDE_SIMPLE_FLOW";
export const LOAD_DATALOAD_DESTINATIONS = "LOAD_DATALOAD_DESTINATIONS";
export const SET_BACKUP_LIST = "SET_BACKUP_LIST";
export const SET_FLOWITEM_LAYOUT_ERRORS = "SET_FLOWITEM_LAYOUT_ERRORS";
export const SET_LOADING_DESTINATIONS = "SET_LOADING_DESTINATIONS";
export const SET_AVAILABLE_DESTINATIONS = "SET_AVAILABLE_DESTINATIONS";
export const SET_REQUESTABLE_DESTINATIONS = "SET_REQUESTABLE_DESTINATIONS";
export const SET_TRACKABLE_DESTINATIONS = "SET_TRACKABLE_DESTINATIONS";
export const SET_DESTINATION_GROUPS = "SET_DESTINATION_GROUPS";

function vars(
    state: IVarsStore = {
        renamingAudienceFolderId: null,
        renamingAudienceFolderType: null,
        page: "",
        companies: [],
        dataEngineUsesTabNav: true,
        ajaxOutstandingRequestCount: 0,
        fieldTreeLastTableKeyLoaded: 0, // when requestFieldTree / loadFields ran last, which table key did it use?
        isItemDragging: false, // Boolean, is something dragging?  Set in beginDrag/endDrag
        itemDraggingType: "", // String, what is the DragDropType of the thing being dragged?
        itemDraggingDetail: null, // Any - Optional detail about the thing being dragged
        genericQbAddFieldQueue: [], // Array of 'queue of items to add to query builder'
        isOnSPA: false,
        isShowingFlowSplash: true,
        renamingFieldFolderId: null, // keeping separate from renamingAudienceFolderId since different Id field
        destinations: [],
        loadingDestinations: false,
        updatingModelBundles: false,
        loadingListDestinationOrder: false,
        loadingCompanies: false,
        migrationIsThinking: false,
        clipboardNodes: [], // FlowItemId
        selectionMode: false,
        clipboardRelations: [],
        multiDestinationTab: 0,
        notFound: false,
        destinationFieldRestrictions: [],
        previousUrlIdForSimpleFlow: null,
        returnToDashboard: false,
        overrideSimpleFlowRedirect: false,
        variableTypes: [],
        modelBundles: [],
        compressionTypes: [],
        layoutTypes: [],
        cassReports: [],
        svFields: [],
        modelFilenames: [],
        modelExcludedVariables: [],
        variableDataTypes: [],
        variableSubTypes: [],
        variableGroupings: [],
        modelVariables: [],
        dataloadDestinations: null,
        clientFieldKey: 0,
        clientField: "",
        clientFixedLength: 0,
        tapadDeliveryTypes: [],
        activationDestinationSchedules: [],
        destinationDedupeSettings: [],
        backupList: [],
        usersTimezoneGuess: null,
        flowItemLayoutErrors: [],
        fieldsExperian: false,
        fieldsMarketplace: false,
        fieldsMyData: false,
        fieldsOther: false,
        availableDestinations: null,
        requestableDestinations: null,
        trackableDestinations: null,
        destinationGroups: null,
    },
    action: VarsTypes
): IVarsStore {
    switch (action.type) {
        case LOAD_SINGLE_VIEW_DESTINATIONS:
            return { ...state, destinations: action.destinations };
        case LOAD_DATALOAD_DESTINATIONS:
            return { ...state, dataloadDestinations: action.destinations };
        case LOAD_FIELDS:
            return { ...state, fieldTreeLastTableKeyLoaded: action.tableKey || 0 };
        case SET_FIELD_TAB_PERMISSIONS:
            return {
                ...state,
                fieldsExperian: action.fieldsExperian,
                fieldsMarketplace: action.fieldsMarketplace,
                fieldsMyData: action.fieldsMyData,
                fieldsOther: action.fieldsOther,
            };
        case SET_LAST_TABLEKEY:
            return { ...state, fieldTreeLastTableKeyLoaded: action.tableKey || 0 };
        case INC_AJAX_COUNT:
            return { ...state, ajaxOutstandingRequestCount: (state.ajaxOutstandingRequestCount || 0) + 1 };
        case DEC_AJAX_COUNT:
            return { ...state, ajaxOutstandingRequestCount: Math.max((state.ajaxOutstandingRequestCount || 0) - 1, 0) };
        case START_RENAMING_FOLDER: {
            const hasType = action.folderType;
            if (hasType) {
                return {
                    ...state,
                    renamingAudienceFolderId: action.folderId,
                    renamingAudienceFolderType: action.folderType!.toUpperCase(),
                };
            }
            return state;
        }
        case STOP_RENAMING_FOLDER: {
            const hasType = action.folderType;
            if (hasType) {
                return { ...state, renamingAudienceFolderId: null, renamingAudienceFolderType: null };
            }
            return state;
        }
        case ASSIGN_NEW_FOLDER_ID: {
            // This means that we had an unsaved folder with negative id (-1)
            // and the server just assigned a folder id to that one.
            // So if we're renaming a negative folder, let's change it to be renaming that folder instead.
            const hasType = action.folderType;
            if (hasType) {
                if (
                    state.renamingAudienceFolderId &&
                    state.renamingAudienceFolderId < 0 &&
                    action.folderType == state.renamingAudienceFolderType
                ) {
                    return { ...state, renamingAudienceFolderId: action.newFolderId };
                }
            }
            return state;
        }
        case START_RENAMING_FIELD_FOLDER: {
            const hasId = action.folderId;
            if (hasId) {
                return {
                    ...state,
                    renamingFieldFolderId: action.folderId,
                };
            }
            return state;
        }
        case STOP_RENAMING_FIELD_FOLDER: {
            const hasId = action.folderId;
            if (hasId) {
                return { ...state, renamingFieldFolderId: null };
            }
            return state;
        }
        case SET_PAGE: {
            return { ...state, page: action.page };
        }
        case SET_TAPAD_DELIVERY_TYPES:
            return { ...state, tapadDeliveryTypes: action.tapadDeliveryTypes };
        case SET_ACTIVATION_DESTINATION_SCHEDULES:
            return { ...state, activationDestinationSchedules: action.activationDestinationSchedules };
        case SET_DESTINATION_DEDUPE_SETTINGS:
            return { ...state, destinationDedupeSettings: action.destinationDedupeSettings };
        case SET_LAYOUT_TYPES:
            return { ...state, layoutTypes: action.layoutTypes };
        case SET_CASS_REPORTS:
            return { ...state, cassReports: action.cassReports };
        case SET_LAYOUT_SVFIELDS:
            return { ...state, svFields: action.svFields };
        case SET_COMPRESSION_TYPES:
            return { ...state, compressionTypes: action.compressionTypes };
        case SET_VAR:
            return { ...state, [action.variable]: action.value };
        case SET_CLIENT_FIELD:
            return {
                ...state,
                clientFieldKey: action.clientFieldKey,
                clientField: action.clientField,
                clientFixedLength: action.fixedLength,
            };
        case GENERIC_QB_ADD_FIELD: {
            const newFieldToAdd = {
                fieldKey: action.fieldKey,
                fieldData: action.fieldData,
                group: action.group,
                index: action.index,
            };
            const newQueue = state.genericQbAddFieldQueue != null ? clone(state.genericQbAddFieldQueue) : [];
            newQueue.push(newFieldToAdd);
            return update(state, {
                genericQbAddFieldQueue: { $set: newQueue },
            });
        }
        case GENERIC_QB_ADD_FIELD_MULTI: {
            const newQueue = state.genericQbAddFieldQueue != null ? clone(state.genericQbAddFieldQueue) : [];

            Array.prototype.forEach.call(action.fieldsWithKeyAndData, field => {
                const newFieldToAdd = {
                    fieldKey: field.fieldKey,
                    fieldData: field.fieldData,
                    group: action.group,
                    index: action.index,
                };

                newQueue.push(newFieldToAdd);
            });

            return update(state, {
                genericQbAddFieldQueue: { $set: newQueue },
            });
        }
        case GENERIC_QB_CLEAR_FIELD: {
            /* Sent after the jquery query builder has added
            whatever rule is in the fieldToInclude queue.
            Clears the queue. */
            // We have access to action.fieldKey - need to only clear that one.

            const queue = state.genericQbAddFieldQueue != null ? state.genericQbAddFieldQueue : [];

            return update(state, {
                genericQbAddFieldQueue: {
                    $set: queue.filter(x => x.fieldKey != action.fieldKey),
                },
            });
        }
        case SET_MODEL_FILENAMES:
            return { ...state, modelFilenames: action.modelFilenames || [] };
        case SET_MODEL_BUNDLES:
            return { ...state, modelBundles: action.modelBundles || [] };
        case SET_MODEL_VARIABLES:
            return { ...state, modelVariables: action.modelVariables || [] };
        case SET_MODEL_VARIABLES_EXCLUDED:
            return { ...state, modelExcludedVariables: action.modelExcludedVariables || [] };
        case SET_UPDATING_MODEL_BUNDLES:
            return { ...state, updatingModelBundles: action.updatingModelBundles };
        case SET_LOADING_DESTINATION_ORDER:
            return { ...state, loadingListDestinationOrder: action.loadingListDestinationOrder };
        case SET_LOADING_COMPANY:
            return { ...state, loadingCompanies: action.loadingCompanies };
        case SET_MIGRATION_THINKING:
            return { ...state, migrationIsThinking: action.isthinking };
        case SET_CLIPBOARD_NODES:
            return { ...state, clipboardNodes: action.clipboardNodes };
        case SET_SELECTION_MODE:
            return { ...state, selectionMode: action.selectionMode };
        case SET_CLIPBOARD_RELATIONS:
            return { ...state, clipboardRelations: action.clipboardRelations };
        case SET_MULTI_DESTINATION_TAB:
            return { ...state, multiDestinationTab: action.multiDestinationTab };
        case SET_NOT_FOUND:
            return { ...state, notFound: action.notFound };
        case SET_VARIABLE_GROUPINGS:
            return { ...state, variableGroupings: action.variableGroupings };
        case SET_VARIABLE_DATA_TYPES:
            return { ...state, variableTypes: action.variableTypes };
        case SET_VARIABLE_DATA_TYPES_FULL:
            return { ...state, variableDataTypes: action.variableDataTypes, variableSubTypes: action.variableSubTypes };
        case LOAD_DESTINATION_FIELD_RESTRICTIONS:
            return { ...state, destinationFieldRestrictions: action.fieldRestrictions };
        case SET_PREVIOUS_URL_ID_SIMPLE_FLOW:
            return { ...state, previousUrlIdForSimpleFlow: action.previousUrlIdForSimpleFlow };
        case SET_DASHBOARD_STATUS:
            return { ...state, returnToDashboard: action.returnToDashboard };
        case SET_REDIRECT_OVERRIDE_SIMPLE_FLOW:
            return { ...state, overrideSimpleFlowRedirect: action.overrideSimpleFlowRedirect };
        case SET_BACKUP_LIST:
            return { ...state, backupList: action.backupList };
        case SET_FLOWITEM_LAYOUT_ERRORS:
            return { ...state, flowItemLayoutErrors: action.flowItemLayoutErrors };
        case SET_LOADING_DESTINATIONS:
            return { ...state, loadingDestinations: action.loadingDestinations };
        case SET_AVAILABLE_DESTINATIONS:
            return { ...state, availableDestinations: action.availableDestinations };
        case SET_REQUESTABLE_DESTINATIONS:
            return { ...state, requestableDestinations: action.requestableDestinations };
        case SET_TRACKABLE_DESTINATIONS:
            return { ...state, trackableDestinations: action.trackableDestinations };
        case SET_DESTINATION_GROUPS:
            return { ...state, destinationGroups: action.destinationGroups };
        default:
            return state;
    }
}
export default vars;

export const companyIdHasZoneReport = (companyId: number): boolean => companyId == 2; // Cablevision
export const companyIdHasSyscodeReport = (companyId: number): boolean => companyId == 8; // Comcast
export const companyIdHasVhoFlagReport = (companyId: number): boolean => companyId == 166; // Verizon
