import React from "react";
import { useDispatch, useSelector } from "react-redux";
import FieldListEntry from "./FieldListEntry";
import FieldListFolder from "./FieldListFolder";
import { setSelectedFields } from "../../actions/fieldTreeActions";
import { fieldMatchesSearchString } from "../../reducers/fields";
import { getTreeSearch, getModalTreeSearch } from "../../reducers/search";
import { createDeepEqualSelector } from "../../helpers/typedHelpers";
import { FieldVisibilityTypes } from "../../helpers/constants";
import fieldFollowsThirdPartyRules from "../../helpers/fieldIsThirdParty";
import { IAppState } from "../../types/stores/index";
import { IFieldsStore } from "../../types/stores/fields";
import { IFieldLabelsWithProperties } from "../../types/stores/fieldLabels";
import { IFieldLabelsWithProperties3 } from "../../types/stores/fieldLabels3";
import { IField } from "../../types/stores/fieldTypes";
import type { FlowFilter } from "../../types/flowTypes";
import tryParseJSON from "../../helpers/tryParseJSON";
import { FieldClassification } from "../../enums/FieldClassifications";
import { getFlowFiltersForSelectedFlow } from "../../reducers/flowFilters";

type Props = {
    editable: boolean;
    fieldKey: string | number;
    treeViewOnUpdate: () => void;
    showAllDuringSearch: boolean;
    TreeType: number;
    isPIIOnly: boolean;
    showHiddenFields: boolean;
    ctrlShift: boolean;
    isModal: boolean;
    showTabs: boolean;
    tabType: number;
};

interface IChildrenIDStruct {
    folderIds: Array<string | number>;
    fieldIds: Array<string | number>;
}

const FieldList: React.FC<Props> = (props: Props) => {
    const {
        editable,
        treeViewOnUpdate,
        showAllDuringSearch,
        TreeType,
        showHiddenFields,
        ctrlShift,
        isModal,
        showTabs,
        tabType,
    } = props;

    const dispatch = useDispatch();

    const getMyFieldIDs = makeGetMyFieldIDs(tabType, showTabs);
    const getFields = makeGetFields();

    const fields: IFieldsStore = useSelector((state: IAppState) => getFields(state, props));
    const childrenIdStruct: IChildrenIDStruct = useSelector((state: IAppState) => getMyFieldIDs(state, props));
    const selectedFields = useSelector((state: IAppState) => state.selected.selectedFields);
    const flowFilters = useSelector((state: IAppState) => state.flowFilters);
    const flowFiltersInFlow = useSelector((state: IAppState) => getFlowFiltersForSelectedFlow(state));

    const onCtrlShift = (lastSelected: number, newSelected: number): void => {
        const { fieldIds } = childrenIdStruct;

        const fieldsByFolder = Object.values(fields.byFolder);
        const fieldList = fieldIds.map(x => {
            const field = fieldsByFolder.find(y => y.workid! == x);
            return field ? field.fieldkey : null;
        });

        const indexA = fieldList.indexOf(lastSelected);
        const indexB = fieldList.indexOf(newSelected);
        if (indexA > indexB) {
            for (let i = indexB; i <= indexA; i++) {
                let field = fieldList[i];
                if (!selectedFields.includes(field)) {
                    dispatch(setSelectedFields(field));
                }
            }
        } else if (indexB > indexA) {
            for (let i = indexA; i <= indexB; i++) {
                let field = fieldList[i];
                if (!selectedFields.includes(field)) {
                    dispatch(setSelectedFields(field));
                }
            }
        }
    };

    const renderField = (workid: string | number) => {
        const { byFolder, byId } = fields;
        const flowFiltersById = flowFilters.byId;
        const field = byFolder[workid];
        let grayoutField = false;

        if (fieldFollowsThirdPartyRules(field.FieldClassification, field.IsThirdParty) && !editable) {
            const theseFilters = Object.values<FlowFilter>(flowFiltersById);
            for (const theseFilter of theseFilters) {
                if (flowFiltersInFlow.filter(x => x.FlowItemId == theseFilter.FlowItemId).length > 0) {
                    const includeObject = tryParseJSON(theseFilter.FlowFilterCriteria) || {};

                    if (includeObject.rules) {
                        for (const rule of includeObject.rules) {
                            let filterField = byId[rule.id];

                            if (
                                fieldFollowsThirdPartyRules(
                                    filterField?.FieldClassification,
                                    filterField?.IsThirdParty
                                ) &&
                                field.companyid !== filterField.companyid
                            ) {
                                grayoutField = true;
                                break;
                            }
                        }
                    }
                }
                if (grayoutField) break;
            }
        }

        return (
            <FieldListEntry
                fieldKey={field.fieldkey}
                key={workid}
                TreeType={TreeType}
                showHiddenFields={showHiddenFields}
                ctrlShift={
                    fieldFollowsThirdPartyRules(field.FieldClassification, field.IsThirdParty) ? false : ctrlShift
                }
                onCtrlShift={onCtrlShift}
                isGrayout={grayoutField}
                tabType={tabType}
                showTabs={showTabs}
                isTableActive={field.TableActive === "Y" ? true : false}
            />
        );
    };

    const renderFolder = (workid: string | number) => (
        <FieldListFolder
            key={workid}
            fieldKey={workid}
            TreeType={TreeType}
            showHiddenFields={showHiddenFields}
            showAllDuringSearch={showAllDuringSearch}
            treeViewOnUpdate={treeViewOnUpdate}
            editable={editable}
            ctrlShift={ctrlShift}
            isModal={isModal}
            tabType={tabType}
            showTabs={showTabs}
        />
    );

    if (!fields || !fields.byParent) {
        return null;
    }

    const { folderIds, fieldIds } = childrenIdStruct;
    if (folderIds.length + fieldIds.length == 0) {
        return <span>(empty)</span>;
    }

    const folderElements = folderIds.map(renderFolder);
    const fieldElements = fieldIds.map(renderField);

    return (
        <div>
            {folderElements}
            {fieldElements}
        </div>
    );
};

export const sortChildIds = (
    childIds: Array<string | number>,
    fieldsById: { [workId: number]: IField }
): Array<string | number> =>
    childIds.sort((a, b) => {
        if (a == "1") {
            return -1;
        }
        if (b == "1") {
            return 1;
        } // Force experian to top
        if (fieldsById[a]["sort"] > fieldsById[b]["sort"]) {
            return 1;
        } // Sort by "Sort"
        if (fieldsById[a]["sort"] < fieldsById[b]["sort"]) {
            return -1;
        }

        const stringA: string | null = fieldsById[a]["text"];
        const stringB: string | null = fieldsById[b]["text"];

        if (!stringA || !stringB) {
            return 1;
        }
        return stringA.localeCompare(stringB); // Tiebreak by name
    });

const makeGetFields = () =>
    createDeepEqualSelector(
        (state: IAppState, props: { TreeType: number }) => {
            let fields: IFieldsStore;
            if (props.TreeType == FieldVisibilityTypes.EXPORT) {
                fields = state.layoutfields;
            } else if (props.TreeType == FieldVisibilityTypes.REPORT) {
                fields = state.reportFields;
            } else {
                fields = state.fields;
            }
            return fields;
        },
        (fields: IFieldsStore) => fields
    );

const makeGetMyFieldIDs = (tabType: number, showTabs: boolean) =>
    createDeepEqualSelector(
        //@ts-ignore
        (state: IAppState, props: Props) => {
            let fields: IFieldsStore;
            if (props.TreeType == FieldVisibilityTypes.EXPORT) {
                fields = state.layoutfields;
            } else if (props.TreeType == FieldVisibilityTypes.REPORT) {
                fields = state.reportFields;
            } else {
                fields = state.fields;
            }
            return fields.byFolder;
        },
        (_, props: Props) => props.fieldKey,
        (state: IAppState, props: Props) => {
            let fields: IFieldsStore;
            if (props.TreeType == FieldVisibilityTypes.EXPORT) {
                fields = state.layoutfields;
            } else if (props.TreeType == FieldVisibilityTypes.REPORT) {
                fields = state.reportFields;
            } else {
                fields = state.fields;
            }

            if (!fields || !fields.byParent || !Object.prototype.hasOwnProperty.call(fields.byParent, props.fieldKey)) {
                return null;
            }
            return fields.byParent[props.fieldKey];
        },
        (state: IAppState, props: Props) => (props.isModal ? getModalTreeSearch(state) : getTreeSearch(state)),
        (_, props: Props) => props.showAllDuringSearch,
        (_, props: Props) => props.isPIIOnly,
        (_, props: Props) => props.editable,
        (state: IAppState) => state.adminDesigner.fieldVisibilityFilter,
        (state: IAppState) => state.fieldsByCompany.enabledFieldVisibilityTypes,
        (state: IAppState) => state.search.selectedFieldLabels,
        (state: IAppState) => state.search.selectedFieldLabels3,
        (state: IAppState) => state.fieldLabels.labels,
        (state: IAppState) => state.fieldLabels3.labels,
        (state: IAppState) => state.session.companyId,
        (state: IAppState) => state.session.enabledFeatures.includes("enhanced-field-search"),
        (
            fieldsById: { [workId: number]: IField },
            selectedFieldId: string | number,
            childIds: Array<string | number>,
            treeSearch: string | Array<string>,
            showAllDuringSearch: boolean,
            isPIIOnly: boolean,
            editable: boolean,
            fieldVisibilityFilter: Array<number>,
            enabledFieldVisibilityTypes: { [fieldId: number]: Array<number> },
            fieldLabelIds: Array<number>,
            fieldLabelIds3: number,
            fieldLabels: Array<IFieldLabelsWithProperties>,
            fieldLabels3: Array<IFieldLabelsWithProperties3>,
            enabledFeatures: boolean
        ) => {
            if (childIds == null) {
                return { folderIds: [], fieldIds: [] };
            }
            if (isPIIOnly) {
                let piiFields: Array<IField> = [];
                Object.keys(fieldsById).forEach(x => {
                    const field: IField = fieldsById[x];
                    if (field.IsPII) {
                        piiFields.push(field);
                    }
                });
                childIds = piiFields.map(x => x.workid!.toString());
            }

            childIds = sortChildIds(childIds, fieldsById);
            let folderIds = childIds.filter(x => !fieldsById[x].isLeaf);
            let fieldIds = childIds.filter(x => fieldsById[x].isLeaf);
            const isExperian = (item: IField) => item.companyid === 0;

            const isMarketplaceFolder = (item: IField) => !item.isLeaf && item.IsThirdParty && item.companyid !== 0;
            const isMarketplaceField = (item: IField) =>
                item.isLeaf && item.FieldClassification === FieldClassification.ThirdParty && item.companyid !== 0;

            const isMarketplaceItem = (item: IField) =>
                (item.FieldClassification === FieldClassification.ThirdParty || item.IsThirdParty == true) &&
                item.companyid !== 0;

            const isMyData = (item: IField) =>
                item.FieldClassification !== FieldClassification.ThirdParty &&
                !item.IsThirdParty &&
                item.companyid !== 0;

            const isUncategorized = (item: IField) =>
                !isExperian(item) && !isMarketplaceFolder(item) && !isMarketplaceField(item) && !isMyData(item);

            const alignedWithParent = (item: IField): boolean => {
                const parent = fieldsById[item.parent];

                // If there's no parent, consider it properly aligned
                if (!parent) return true;

                // Check if the item and its parent belong to the same category
                if (isExperian(item) && isExperian(parent)) return true;
                if (isMarketplaceItem(item) && isMarketplaceItem(parent)) return true;
                if (isMyData(item) && isMyData(parent)) return true;
                if (isUncategorized(item) && isUncategorized(parent)) return true;

                // If none of the conditions are met, it's misaligned
                return false;
            };

            if (showTabs) {
                if (tabType == 0) {
                    // Experian
                    folderIds = childIds.filter(x => !fieldsById[x].isLeaf && isExperian(fieldsById[x]));
                    fieldIds = childIds.filter(x => fieldsById[x].isLeaf && isExperian(fieldsById[x]));
                } else if (tabType == 1) {
                    // Marketplace
                    folderIds = childIds.filter(x => !fieldsById[x].isLeaf && isMarketplaceFolder(fieldsById[x]));
                    fieldIds = childIds.filter(x => fieldsById[x].isLeaf && isMarketplaceField(fieldsById[x]));
                } else if (tabType == 2) {
                    // My Data
                    folderIds = childIds.filter(x => !fieldsById[x].isLeaf && isMyData(fieldsById[x]));
                    fieldIds = childIds.filter(x => fieldsById[x].isLeaf && isMyData(fieldsById[x]));
                } else if (tabType == 3) {
                    // Other: Misconfigured fields and folders for Admin > Edit Fields
                    const atTopOfFieldTree = !selectedFieldId || selectedFieldId == "#";
                    const allItemIds = Object.keys(fieldsById).map(Number);
                    const allOrphanedItemIds = allItemIds.filter(x => !alignedWithParent(fieldsById[x]));
                    const selectedItemIsAnOrphan = allOrphanedItemIds.includes(selectedFieldId as number);

                    let filteredIds = childIds.filter(x => isUncategorized(fieldsById[x]));

                    if (atTopOfFieldTree) {
                        filteredIds.push(...allOrphanedItemIds);
                    } else if (selectedItemIsAnOrphan) {
                        filteredIds = childIds.filter(x => alignedWithParent(fieldsById[x]));
                    }

                    folderIds = filteredIds.filter(x => !fieldsById[x].isLeaf);
                    fieldIds = filteredIds.filter(x => fieldsById[x].isLeaf);
                }
            } else if (!editable) {
                // Marketplace feature is off, exclude third-party data
                folderIds = childIds.filter(x => !fieldsById[x].isLeaf && !fieldsById[x].IsThirdParty);
                fieldIds = childIds.filter(x => fieldsById[x].isLeaf && !fieldsById[x].IsThirdParty);
            }

            const selectedFieldLabels = fieldLabels
                .filter(x => fieldLabelIds.includes(x.FieldLabelId))
                .map(x => x.FieldLabelName);

            const selectedFieldLabels3 = fieldLabels3
                .filter(x => fieldLabelIds3 == x.FieldLabelId)
                .map(x => x.FieldLabelName);

            if (selectedFieldLabels.length > 0) {
                fieldIds = fieldIds.filter(x => {
                    const fieldIdLabels = fieldsById[x].FieldLabels && fieldsById[x].FieldLabels.split(", ");
                    return fieldIdLabels ? fieldIdLabels.some(label => selectedFieldLabels.includes(label)) : false;
                });
            }

            if (selectedFieldLabels3.length > 0) {
                fieldIds = fieldIds.filter(x => {
                    const fieldIdLabels3 = fieldsById[x].UmbrellaFieldId != null ? fieldsById[x].UmbrellaFieldId : "";
                    return fieldIdLabels3 ? fieldIdLabels3 == Number(selectedFieldLabels3) : false;
                });
            }

            if (editable) {
                fieldIds = fieldIds.filter(
                    x =>
                        // x isn't a fieldkey any more, its a workid
                        (fieldsById[x] &&
                            // none option is selected in visibility filter and field doesn't have visibility types assigned
                            fieldVisibilityFilter.includes(FieldVisibilityTypes.NONE) &&
                            !enabledFieldVisibilityTypes[fieldsById[x].fieldkey]) ||
                        // field has visibility types assigned
                        (enabledFieldVisibilityTypes[fieldsById[x].fieldkey] &&
                            // the field has at least one of the selected visibility types in the filter
                            fieldVisibilityFilter.some(f =>
                                enabledFieldVisibilityTypes[fieldsById[x].fieldkey].includes(f)
                            ))
                );
            }

            if (treeSearch && !showAllDuringSearch) {
                fieldIds = fieldIds.filter(fieldId =>
                    fieldMatchesSearchString(fieldsById[fieldId], treeSearch, enabledFeatures)
                );
            }

            return { folderIds, fieldIds };
        }
    );

export default FieldList;
